@import "./theme.scss";

// Then import Bootstrap an BootstrapVue SCSS files (order is important)
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";

.aspect-ratio-1-1 {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  display: block;

  > * {
    width: 100%;
    position: absolute;
    height: 100%;
  }
}

button {
  &.stuff {
    padding: 0;
    overflow: hidden;
    border: 0;

    img {
      max-width: 100%;
    }
  }
}
