// Solar 5.1.1
// Bootswatch

$theme: "solar";

//
// Color system
//

// stylelint-disable
$white: #fff;
$gray-100: #fdf6e3;
$gray-200: #eee8d5;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #839496;
$gray-700: #495057;
$gray-800: #073642;
$gray-900: #002b36;
$black: #000;

$blue: #b58900;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #d33682;
$orange: #fd7e14;
$yellow: #cb4b16;
$green: #2aa198;
$teal: #20c997;
$cyan: #268bd2;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$min-contrast-ratio: 2.5;

// Body

$body-bg: $gray-900;
$body-color: $gray-600;

// Links

$link-color: $success;
$link-hover-color: $link-color;

// Components

$component-active-color: rgba(255, 255, 255, 0.75);
$component-active-bg: $gray-800;

// Fonts

$font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

// Tables

$table-accent-bg: rgba($white, 0.05);
$table-hover-bg: rgba($white, 0.075);
$table-border-color: $component-active-bg;
$table-dark-bg: $gray-500;
$table-dark-border-color: darken($gray-500, 3%);
$table-dark-color: $body-bg;

$table-bg-scale: 0;

// Forms

$input-bg: #a9bdbd;
$input-disabled-bg: #657b83;

$input-color: $gray-800;
$input-border-color: rgba($black, 0.15);
$input-placeholder-color: #657b83;
$input-group-addon-color: $gray-600;
$input-group-addon-bg: $gray-800;

$form-check-input-bg: rgba(255, 255, 255, 0.75);
$form-check-input-border: 1px solid $white;
$form-check-input-checked-bg-color: $primary;

$form-switch-color: rgba(255, 255, 255, 0.75);
$form-switch-focus-color: $form-switch-color;

$form-range-track-bg: $gray-800;
$form-range-thumb-bg: $primary;

$form-file-button-color: $gray-600;

// Dropdowns

$dropdown-bg: $gray-800;
$dropdown-divider-bg: $body-bg;
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: rgba(255, 255, 255, 0.75);
$dropdown-link-hover-bg: $body-bg;

// Navs

$nav-tabs-border-color: $gray-800;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-active-color: $component-active-color;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $nav-tabs-border-color;

// Navbars

$navbar-light-color: rgba($black, 0.4);
$navbar-light-active-color: rgba($black, 0.7);

// Pagination

$pagination-bg: transparent;
$pagination-border-color: $gray-800;
$pagination-hover-bg: $gray-800;
$pagination-hover-border-color: $gray-800;
$pagination-active-color: rgba(255, 255, 255, 0.75);
$pagination-active-bg: $gray-800;
$pagination-active-border-color: $gray-800;
$pagination-disabled-color: $gray-800;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: $gray-800;

// Cards

$card-border-color: rgba($gray-900, 0.95);
$card-cap-bg: rgba($gray-800, 0.25);
$card-bg: rgba($gray-200, 0.125);

// Popovers

$popover-bg: $gray-800;
$popover-border-color: $body-bg;
$popover-header-bg: $gray-800;

// Toasts

$toast-background-color: lighten($body-bg, 5%);
$toast-border-color: rgba(0, 0, 0, 0.2);
$toast-header-color: $body-color;
$toast-header-background-color: $toast-background-color;
$toast-header-border-color: $toast-border-color;

// Modals

$modal-content-bg: $gray-800;
$modal-content-border-color: $body-bg;
$modal-header-border-color: $body-bg;

// Progress bars

$progress-bg: $gray-800;
$progress-bar-color: $primary;

// List group

$list-group-color: $white;
$list-group-bg: transparent;
$list-group-border-color: $gray-800;
$list-group-hover-bg: $gray-800;
$list-group-active-color: $white;
$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: transparent;
$list-group-action-color: $body-color;
$list-group-action-hover-color: $white;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem;
$breadcrumb-padding-x: 0.75rem;
$breadcrumb-bg: $component-active-bg;
$breadcrumb-active-color: $gray-500;
$breadcrumb-border-radius: 0.25rem;

// Close

$btn-close-color: $white;

// Code

$pre-color: inherit;
